<template>
  <!--ESTO ES HORRENDO! si encontras una mejor forma de hacer funcionar paycoment avisame-->
  <!--  Ha sido casi imposible para mi armar algo mejor que esto!
  Banco Sabadel y Paycoment es un tremenda cagada. -->
  <!--  Explicaion basica de porque esto esta asi, Paycoment lo que hace es agregarte un iframe en los inputs
  a su vez ellos manejan el evento submit entonces ellos envian el form completo
  uno esperaria que se le pueda pegar a la api para obtener el token con js, pero no funciona asi.
   Yo gaste mas de 40 horas en intentar! -->
  <v-container>
    <v-row>
      <v-form role="form" id="paycometPaymentForm" :action="urlForm"  method="POST">
        <input type="hidden" name="amount" :value="amount">
        <input type="hidden" data-paycomet="jetID" value="uvoEM6zJcFT5XaPfxKAYrVwpsGgL8e9k">
        <v-col cols="12">
          <p :class="($root.isMobile() ?'font-weight-thin ':'primary--text ')+'text-uppercase'">Nombre del titular</p>
          <v-text-field  name="username" data-paycomet="cardHolderName" placeholder="" required :outlined="$root.isMobile()" :solo="!$root.isMobile()" :flat="!$root.isMobile()"></v-text-field>
        </v-col>
        <v-col cols="12">
          <p :class="($root.isMobile() ?'font-weight-thin ':'primary--text ')+'text-uppercase'">Card number</p>
          <div class="input-group">
            <div id="paycomet-pan" :class="!$root.isMobile() ? 'no-border': ''"></div>
            <input paycomet-style="width: 90%; height: 25px; font-size:16px; padding-left:7px; padding-top:8px; border:none;" paycomet-name="pan" required />
          </div> <!-- input-group.// -->
        </v-col> <!-- form-group.// -->
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <p :class="($root.isMobile() ?'font-weight-thin ':'primary--text ')+'text-uppercase'">expiración</p>
              <v-row>
                <v-col cols="5">
                  <v-text-field data-paycomet="dateMonth" placeholder="MM" :outlined="$root.isMobile()" :solo="!$root.isMobile()" :flat="!$root.isMobile()"></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field data-paycomet="dateYear" placeholder="YY" :outlined="$root.isMobile()" :solo="!$root.isMobile()" :flat="!$root.isMobile()"></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6">
              <p :class="($root.isMobile() ?'font-weight-thin ':'primary--text ')+'text-uppercase'">CCV</p>
              <div id="paycomet-cvc2" :class="!$root.isMobile() ? 'no-border': ''"></div>
              <input paycomet-name="cvc2" paycomet-style="border:0px; width: 100%; height: 30px; font-size:16px; padding-left:8px; padding-top:4px;" required />
            </v-col>

          </v-row>
        </v-col>
        <v-btn block x-large :class="($root.isMobile()? 'orange ': 'primary ')+'white--text mt-4'" type="submit" tile elevation="0" @click="sending = true" :loading="sending" :disabled="cantSend">
          <v-icon :small="$root.isMobile()" :left="!$root.isMobile()">{{$root.isMobile()?'mdi-star-three-points-outline':'mdi-cart-outline'}}</v-icon> {{btnConfirm}}
        </v-btn>
      </v-form>
      <div id="paymentErrorMsg">

      </div>
    </v-row>
  </v-container>


</template>

<script>
export default {
  name: "CardForm",
  props: ['amount', 'urlForm', 'btnConfirm', 'cantSend'],
  data(){
    return{
      sending: false,
    }
  },
  mounted() {
    let themejs = document.createElement('script')
    themejs.setAttribute('src', 'https://api.paycomet.com/gateway/paycomet.jetiframe.js?lang=es')
    document.head.appendChild(themejs)
  }
}
</script>

<style lang="scss">
#paycometPaymentForm{
  .v-text-field__details{
    display: none;
  }

  #paycomet-cvc2, #paycomet-pan{
    &.no-border{
      border: none !important;
    }
    width: 100%;
    height: 56px;
    padding-left: 7px;
    padding-top: 8px;
    border: 1px gray solid;
    border-radius: 5px;
    background: white;
  }
}
</style>