<template>
  <div id="paymentFood">
    <v-container>
      <div class="d-flex align-center my-4">
        <v-btn
            fab
            x-small
            elevation="0"
            @click="$router.go(-1)"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div class="text-h6 px-2">Pago</div>
      </div>
      <card-form :amount="price" :url-form="`${$store.state.api_backend}api/order/${order.id}/pay`" btn-confirm="Confirmar compra"></card-form>
    </v-container>
  </div>
</template>

<script>
import CardForm from "@/components/CardForm";
export default {
  name: "Payment",
  components: {CardForm},
  mounted() {
    this.$store.commit('setLoading', false)
  },
  computed:{
    order(){
      return this.$store.state.order
    },
    price(){
      const items = this.$store.state.order ? this.$store.state.order.items : [];
      let price = 0;
      items.forEach(item =>{
        price += item.quantity * item.unit_price
      })
      return price
    }
  }
}
</script>

<style scoped>

</style>